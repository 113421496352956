body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.stud-list-item {
  float: left;
  width: 150px;
  min-height: 150px;
  border: 3px rgb(216, 216, 213) solid;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer; 
}

.stud-list-item:hover {
  border: 3px rgb(216, 236, 213) solid;
  background-color: rgb(240, 255, 240);
}

.stud-list-item-active {
  border: 3px black solid;
  font-weight: bold;
  color: black;
}

.slider-tick-label-container {
  overflow: hidden;
  margin-right: -20px;
}