.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}



.CollarOption{
  border: 1px solid black;
  border-radius: 5px;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
}


.CollarOption:hover, .CollarOption-active {
  background-color: rgb(255, 255, 255);
  border: 3px solid black;
}

.CollarOption:hover {
  border: 4px solid black;
  margin: 2px;
}

.CollarOption:active {
  border: 4px green black;
}


.ColorPicker .btn-group-vertical .active {
  font-weight: bold;
}




.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {background-color: #ddd}

.dropdown-content span .selected {
  font-weight: bold;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}